<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/raceManagement/ibcHomeManagement'}">IBC赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item>成绩查询</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>成绩信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="80px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="届" prop="ibcSession">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.ibcSession"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in dict_ibcSession"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="季度" prop="ibcSeason">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.ibcSeason"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in dict_ibcSeason"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="轮次" prop="ibcRound">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                clearable
                v-model="queryForm.ibcRound"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in dict_ibcRound"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="enrollmentLevel">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.enrollmentLevel"
                :disabled="queryForm.ibcRound === '' ? true : false"
                clearable
              >
                <el-option
                  v-for="item in dict_sspcnGroup"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                oninput="value=value.replace(/[^\d]/g,'')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="国籍" prop="country">
              <el-input
                v-model="queryForm.country"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="晋级状态" prop="promotion">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.promotion"
                :disabled="queryForm.ibcRound === '' ? true : false"
                clearable
              >
                <el-option
                  v-for="item in dict_isPromotion"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 成绩信息列表 -->
    <el-card style="margin-top: 15px" v-if="isShowList === true">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="10">
          <span
            style="
              display: -moz-inline-box;
              display: inline-block;
              width: 140px;
            "
            >IBC成绩实时查询</span
          >
          <span style="color: blue; font-size: 14px" v-if="queryForm.ibcRound === '1' && this.queryForm.ibcRound !== ''">{{
            "已晋级" +
            PromotionNumData.promotionOne +
            "人"
          }}</span>
          <!-- "未比赛" +
            (PromotionNumData.promotionTwo - PromotionNumData.promotionOne) +
            "人" -->
          <span style="color: blue; font-size: 14px" v-else-if="this.queryForm.ibcRound !== ''">{{
            "已晋级" +
            PromotionNumData.promotionOne +
            "人"
          }}</span>
          <!--  +
            "未晋级" + (PromotionNumData.promotionTwo - PromotionNumData.promotionOne) + "人" -->
        </el-col>
        <el-col :span="14" style="text-align: right;">
          <div class="div_algin_right">
            <!-- <el-button type="primary" @click="exportingScoreFile"
              >导出信息表</el-button
            > -->
            <el-button type="primary" @click="editInfoList">编辑</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="国籍" prop="country"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column
          label="性别"
          prop="sex"
          :formatter="genderFormatter"
        ></el-table-column>
        <el-table-column label="省市区" prop="provence" width="200px">
                  <template scope="scope" >
                      <span v-if="scope.row.province"
                          >{{ scope.row.province }}/{{
                              scope.row.city
                          }}/{{ scope.row.area }}</span
                      >
                      <span v-else>未设置</span>
                  </template>
              </el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column
          label="Round 1"
          prop="scoreOne"
          v-if="queryForm.ibcRound === '1' || queryForm.ibcRound === '2' || queryForm.ibcRound === ''"
        ></el-table-column>
        <el-table-column
          label="Round 2"
          prop="scoreTwo"
          v-if="queryForm.ibcRound === '2' || queryForm.ibcRound === ''"
        ></el-table-column>
        <el-table-column
          label="Round 1+2"
          prop="total"
          v-if="queryForm.ibcRound === '2'"
        ></el-table-column>
        <!-- <el-table-column
          label="Round 3"
          prop="scoreThree"
          v-if="queryForm.ibcRound === '3' || queryForm.ibcRound === ''"
        ></el-table-column> -->
        <!-- <el-table-column
          label="Round 4"
          prop="scoreFour"
          v-if="queryForm.ibcRound === '4' || queryForm.ibcRound === ''"
        ></el-table-column> -->
        <el-table-column label="晋级状态" v-if="queryForm.ibcRound !== '' && queryForm.ibcRound !== '5'">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.promotion === 0" size="mini"
              >未晋级</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.promotion === 1"
              size="mini"
              >晋级</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.promotion === -1 && queryForm.ibcRound === '1'"
              size="warning"
              >未参赛</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.promotion === -1 && queryForm.ibcRound !== '1'"
              size="warning"
              >待设置</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="排名"
          prop="promoted"
          v-if="queryForm.ibcRound === '5' || queryForm.ibcRound === '4'"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
// import axios from 'axios'
import { getIbcMatchPromotionList, getIbcPromotionNum, queryIbcSeason } from '@/http/api'

export default {
  data () {
    return {
      isShowList: false,
      editState: 0, // 该界面是否处于编辑界面
      total: 0,
      dataList: [],
      PromotionNumData: {
        promotionOne: 0,
        promotionTwo: 0
      },
      queryForm: {
        enrollmentLevel: null,
        activityType: this.$chnEngStatusCode.ibcActivityType,
        ibcSession: this.$ibcManager.ibcSession,
        ibcSeason: this.$ibcManager.ibcSeason,
        ibcRound: '1',
        pageNum: 1,
        pageSize: 10,
        userName: '',
        phone: '',
        country: '',
        promotion: ''
      },
      dict_gender: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.dict_gender = value }),
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_sspcnGroup: this.$userInfo.dict_group(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcRound: this.$userInfo.getDataList(this, 'ibc_round').then(value => { this.dict_ibcRound = value }),
      dict_grade: this.$userInfo.getGradeData(),
      dict_isPromotion: [
        {
          dictCode: -1,
          dictValue: -1,
          dictLabel: '未设置'
        },
        {
          dictCode: 0,
          dictValue: 0,
          dictLabel: '未晋级'
        }, {
          dictCode: 1,
          dictValue: 1,
          dictLabel: '已晋级'
        }
      ]
    }
  },
  created () {
    this.getQueryIbcSeason()
  },
  methods: {
    getQueryIbcSeason () {
      queryIbcSeason().then((res) => {
        const newObj = Object.entries(res.data).reduce((obj, [key, value]) => {
          obj[key] = value.toString()
          return obj
        }, {})
        console.log('newObj', newObj)
        this.queryForm = { ...this.queryForm, ...newObj }
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取成绩列表数据
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getIbcMatchPromotionList(this.queryForm).then((res) => {
        if (this.queryForm.ibcRound !== '') {
          this.getProgressStatus()
        }
        this.isShowList = true
        this.dataList = res.data.list ? res.data.list : []
        this.total = res.data.total ? res.data.total : 0
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取晋级未晋级人数
    getProgressStatus () {
      getIbcPromotionNum(this.queryForm).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.PromotionNumData = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    selectChanged () {
      this.isShowList = false
    },
    // 重置搜索内容
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_gender) {
        if (Object.hasOwnProperty.call(this.dict_gender, key)) {
          const element = this.dict_gender[key]
          if (element.dictValue === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      console.log('data', data.enrollmentLevel)
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === data.enrollmentLevel) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 导出信息表
    exportingScoreFile () {
      var downLoadUrl = this.$http.defaults.baseURL + '/ibc/matchScore/excelIbcScore'
      var formData = { ibcSession: this.queryForm.ibcSession, ibcSeason: this.queryForm.ibcSeason, ibcRound: this.queryForm.ibcRound }
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', formData)
    },
    // 编辑按钮点击事件
    editInfoList () {
      this.$router.push(
        {
          path: '/raceManagement/ibc/ibcExamination_set_score',
          query: { queryFormData: this.queryForm }
        }
      )
    }
  }
}
</script>

<style>
</style>
